* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

h4 {
  margin: 0%;
}

/* ====== GOOGLE REVIEW CSS ========== */

.Trust {
  display: flex;
  color: black !important;
  border-bottom: 2px solid #e0ae17;
  list-style-type: none;
  text-decoration: none;
  margin-top: 4px;
  justify-content: center;
}

.Trustpilot_logo {
  width: 70%;
  height: 70%;
}

/* ====================  NAVBAR CSS START ==================== */
.header {
  background-color: #1F1A17;
  padding: 0%;
  margin: 0%;

}

.Nav_logo {
  width: 140px;
  height: auto;
  margin: 0%;
  padding: 0%;
}

.nav-Title h4 {
  color: white;
  font-weight: none;
  font-style: italic;
}

.nav_list ul #navtxt {
  margin-left: 3%;
}

.nav_list ul li .nav-link {
  color: white !important;
  margin-left: 80px;
  margin-top: 22.5px;
  font-size: 14px;
  transition: 0.2s ease-in-out;
}

.nav_list ul li .nav-link:hover {
  transition: 0.2 ease-in-out;
  color: #F1CC46 !important;
}

.callingtxt {
  color: white;
  text-align: center;
  font-size: 11px;
  height: 8.5vh;
}

.callingtxt h4 {
  margin: 0%;
  /* padding-top: 10px; */
  color: #ECC52C;
}

/* ================  NAVBAR CSS END =================== */


/* ==================== HOME PAGE CSS START ============ */
.d-home {
  background-image: url(../src/images/Main_Page_Top_Pic.png);
  /* opacity: 0.9; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 60vh;
  justify-content: center;
  /* padding-top: 3%; */
  /* padding-bottom: 3%; */
  /* padding: 140px 10px; */
}

.Designation {
  margin-top: 20%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container button {
  padding: 12px 50px;
}

.company_vision h3 {
  font-weight: bold;
  color: #e0ae17;
}

.company_vision p {
  text-align: justify;
}

.company_vision img {
  border-top-left-radius: 60px;
  border-bottom-right-radius: 60px;
  /* width: 80vh;
  height: 50vh; */
}


.Slider {
  margin-top: 5%;
}

/* ==================== HOME PAGE CSS END ============ */


/* =========== FLIGHT CHECK FORM START ================ */
.d-form {
  color: black;
  font-weight: 600;
  box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
  -webkit-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
  border-radius: 10px;
  background-color: white;
  width: 95% !important;
  padding: 40px;
  margin-left: 50px;
}

.d-form .form-control {
  height: 50px;
  padding: 0px 25px;
  font-size: 16px;
}

.d-form .SubmitBTn {
  margin: auto;
  justify-content: center;
  width: 30%;
  padding: 10px 0px;
  font-size: 16px;
  transition: 0.7s;
  display: flex;
}

.d-form .SubmitBTn:hover {
  outline: none !important;
  background: transparent !important;
  color: #F1CC46 !important;
  border: 1px solid #F1CC46 !important;
}

.fly_form {
  color: black;
  font-weight: 600;
  box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
  -webkit-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
  border-radius: 10px;
  background-color: white;
  width: 95% !important;
  padding: 40px;
  margin-left: 50px;
}

.fly_form .form-control {
  height: 50px;
  padding: 0px 25px;
  font-size: 16px;
}

.fly_form .SubmitBTn {
  margin: auto;
  justify-content: center;
  width: 30%;
  padding: 10px 0px;
  font-size: 16px;
  transition: 0.7s;
  display: flex;
}

.fly_form .SubmitBTn:hover {
  outline: none !important;
  background: transparent !important;
  color: #F1CC46 !important;
  border: 1px solid #F1CC46 !important;
}


.flight {
  text-decoration: none;
  border: 1px solid #F1CC46;
  width: 10%;
  outline: none;
  background-color: #F1CC46;
  border-radius: 20px;
  transition: 0.5s;
  color: white;
  font-size: 20px;
  font-weight: 500;
  padding: 10px;
  margin: auto;
  margin-bottom: 20px;
  align-items: right;
}

.radio-btn {
  margin: 0 20px;
}

.radio-btn .btn {
  margin: 20px 10px 20px 0;
  box-shadow: none;
}

.radion-btn .btn::after {
  content: "";
  height: 15px;
  width: 15px;
  top: -2px;
  left: -1px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  border: 1px solid black;
  visibility: visible;
  border-radius: 50%;
}

.radio-btn .btn:checked::after {
  content: "";
  background-color: black;
  transition: 0.5s;
}

.radio-btn span {
  color: #F1CC46 !important;
  margin-right: 15px;
  font-size: 12px;
}

.FLightModes {
  display: flex;
  margin-left: 50px;
  margin-top: 15%;
}

.FLightModes label {
  /* opacity: 0.8; */
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1F1A17;
  font-size: 20px;
  /* height: 80px; */
  border-radius: inherit;
  cursor: pointer;
  border: none;
  padding: 20px;
}

.FLightModes input[type="radio"] {
  display: none;
}

.FLightModes input[type="radio"]:checked+label {
  color: #F1CC46 !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
}

.active {
  color: #F1CC46 !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
}

.FLightModes label:hover {
  color: #F1CC46 !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
}

.btn-get-submit {
  text-decoration: none;
  width: 100%;
  border: 2px solid #F1CC46;
  background-color: white;
  border-radius: 30px;
  transition: 0.5s;
  color: #F1CC46;
  font-size: 20px;
  font-weight: 500;
  /* padding: 10px 30px; */
  margin: auto;
  margin-bottom: 20px;
}

.btn-get-submit:hover {
  background-color: #F1CC46;
  color: white;
}

/* =========== FLIGHT CHECK FORM END ================ */


/*================ FLIGHT Searching function CSS START  ================*/

.dataResult {
  width: 100%;
  /* height: 200px; */
  position: absolute;
  z-index: 10;
  background-color: white !important;
  color: black;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
}

.formImput {
  position: relative;
  z-index: 2;
}

.dataResult .dataItem:hover {
  background-color: rgb(195, 195, 195);
  transition: 0.2s;
  /* opacity: -9; */
  color: black;
  cursor: pointer;
}

.disable {
  display: none;
  visibility: hidden;
}


/* ========AIRLINES SEARCH  */
.AirlinesResult {
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: white !important;
  color: black;
  overflow: hidden;
  max-height: 200px;
  /* padding: 0px 10px; */
  overflow-y: auto;
}

.formImput {
  position: relative;
  z-index: 2;
}

.AirlinesResult .AirlinesItem:hover {
  background-color: rgb(195, 195, 195);
  transition: 0.2s;
  /* opacity: -9; */
  color: black;
  cursor: pointer;
}

/*================ FLIGHT Searching function CSS  END ================*/


/* =========== FLY PAGE CSS START ================ */

.d-fly {
  background-image: url(../src/images/flights.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
}

.d-fly .FLightModes {
  margin-top: 15%;
}

.Airlinesimg {
  margin-top: 18%;
  width: 70%;
  height: 70%;
}

/* =========== FLY PAGE CSS END ================ */


/* ================ ABOUT PAGE CSS START ==================== */
.AboutImg {
  background-image: url(../src/images/About_Us.jpg);
  opacity: 0.8;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  justify-content: center;
  background-position: center;
  /* padding-top: 10%; */
}

.AboutImg .About {
  color: white;
  text-align: center;
  justify-content: center;
  font-weight: bolder;
  align-items: center;
  font-size: 40px;
}

.AboutContent {
  line-height: 32px;
}

.AboutContent strong {
  color: #e0ae17;
}


/* ============= ACCODION CSS ================ */
#main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 30px;
  border: 0;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: black;
  display: block;
  text-align: left;
  background: #ffe472;
  color: #222;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #1C2029;
  color: white;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  background: #ffe472;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #ffe472;
  line-height: 30px;
  color: #222;
}

/* ================ ABOUT PAGE CSS END ==================== */


/* ================ CONTACT  PAGE CSS START ==================== */
.Contactimg {
  /* opacity: 0.5; */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 78vh;
  background-position: center;
  padding-top: 10%;
  background-image: url(../src/images/Contact_us.jpg);
}

.contact_content h1,
h4 {
  color: #e0ae17;
  font-weight: 580;

}

/* ================ CONTACT  PAGE CSS END ==================== */



/* =====================  HAJJ & UMMRAH CSS START ============ */
.hujjContent {
  line-height: 30px;
}

.hujjContent h3 {
  font-size: 19px;
  font-weight: bolder;
  width: 50;
  text-align: center;
  color: #e0ae17;
  line-height: 35px;
}

.hujjContent strong {
  color: #e0ae17;
}

/* =====================  HAJJ & UMMRAH CSS END ============ */



/* ============== FOOTER CSS START ================= cc9b09*/
footer {
  /* background-image: linear-gradient(to bottom, #e0ae17,#e0ae17, #f5c310); */
  background-color: #1F1A17;
  padding: 5rem 0 0;
  box-shadow: inset 0 1.5rem 1.5rem rgba(0, 0, 0, 0.2);
}

.footer_container {
  margin-left: 9%;
  line-height: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

footer h4 {
  color: #e0ae17;
  margin-bottom: 0.6rem;
}

footer ul {
  margin: 0;
  padding: 0;
}

footer ul li {
  padding: 0.4rem 0;
  list-style-type: none;
}

footer ul a {
  opacity: 0.75;
  color: white;
  text-decoration: none;
  transition: 1s;
  text-transform: none;
}

footer ul a:hover {
  /* letter-spacing: 0.2rem; */
  color: #e0ae17;
  opacity: 1;
}

footer address {
  color: white;
}

.footer_copyright {
  text-align: center;
  padding: 1rem 0;
  border-top: 2px solid #fff;
  color: white;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}

.footer_copyright img {
  width: 20%;
  height: 50%;
}

.footer-Title h2 {
  color: white;
  font-weight: none;
  font-style: italic;
}

/* ============== FOOTER IMAGES ==================== */

.images {
  text-align: center;
  display: flex;
  justify-content: center;
  align-self: center;
}

.images .img2 {
  border-left: 3px solid gray;
  height: 80px;
}

.images .img1 {
  height: 50px;
}

.social_link {
  display: flex;
  /* max-width: 20px; */
  line-height: 15px;
}

.social_link a {
  color: white;
  margin-left: 15px;
  font-size: 14px;
}

.social_link a:hover {
  color: #0095f6;
}

/* ============== FOOTER CSS END ================= */




/* ============== WHATSAPP ICON CSS START ================= */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 50px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  opacity: 1;
  animation: light 8s ease infinite alternate;
}

@keyframes light {

  0%,
  20% {
    opacity: 1;
  }

  30%,
  40% {
    opacity: 0;
  }

  50%,
  80% {
    opacity: 1;
  }

  90%,
  100% {
    opacity: 0;
  }
}

.whatsapp-icon {
  margin-top: 16px;
  color: #fff;
}


/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

/* ============== WHATSAPP ICON CSS END ================= */


.images {
  padding: 15px 10px;
  align-self: center;
  text-align: center;
}

.message {
  text-align: center;
  padding: 40px;
}

.message h3 {
  color: #e0ae17;
}

.message h2 {
  color: black;
}

.message .ThankMsg h5 {
  color: rgb(44, 44, 44);
  font-size: 15px;
}



/*================ TERMS & CONDITION CSS  START ================*/
.Terms {
  overflow: hidden;
}

.Terms img {
  width: 100% !important;
}

.Terms .content {
  margin-top: 40px;
}

.Terms .content p {
  line-height: 32px;
  text-transform: capitalize;
  margin-top: 5px;
}

.Terms .content strong {
  /* color: #e0ae17; */
  color: #F0C632;
}

/*================ TERMS & CONDITION CSS END ================*/


/* ======================================= MEDIA QUIERY ========================================================= */
@media only screen and (max-width: 1200px) {

  /* ============== HEADER ============== */
  .header {
    background-color: #1F1A17 !important;
    padding: 4px 0px !important;
  }

  .header ul .nav-link {
    font-size: 20px !important;
    margin: 0px 50px;
  }
}

@media only screen and (max-width: 1067px) {

  /* ============== HEADER ============== */
  .header {
    padding: 2px 0px;
    /* padding-right: 13%; */
  }

  .header ul .nav-link {
    font-size: 12px !important;
  }

  /* ===== FLIGHTMODES ============= */
  .d-home {
    padding-top: 10%;
    padding-bottom: 3%;
    font-size: 10px;
  }

  .FLightModes {
    margin-left: 25px;
  }

  .FLightModes button {
    opacity: 0.8;
    color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: black;
    font-size: 20px;
    height: 60px;
    border-radius: inherit;
  }

  /* .d-form {
    padding: 20px;
    margin-left: 25px;
  } */
  .dataResult {
    font-size: 13px;
    line-height: 5px;
    width: 100%;
  }

  .dataResult .dataItem {
    padding: 10px;
  }

  .AirlinesResult {
    font-size: 13px;
    line-height: 5px;
    width: 100%;
  }

  .AirlinesResult .AirlinesItem {
    padding: 10px;
  }

  /* ============= FOOTER CSS ============== */
  .footer_container {
    margin: 0% !important;
  }

  .images {
    overflow: hidden !important;
    width: 91%;
    height: 100%;
  }

  .nav_list ul li .nav-link {
    text-align: center;
    margin-left: 0%;
  }

  .images .img2 {
    border-left: 3px solid gray;
    height: 58px;
  }

  .images .img1 {
    height: 38px;
    margin-left: 90px;
  }
}

@media only screen and (max-width: 992px) {
  .nav_list ul li .nav-link {
    text-align: center;
    font-size: 14px !important;
  }

  /* ============= FOOTER CSS ============== */
  .footer_container {
    margin: 0%;
  }

  .footer_container article {
    margin: 0px 20px;
  }

  .images {
    overflow: hidden !important;
    width: 91%;
    height: 100%;
  }

  .nav_list ul li .nav-link {
    text-align: center;
    margin-left: 0%;
  }

  .images .img2 {
    border-left: 3px solid gray;
    height: 58px;
  }

  .images .img1 {
    height: 38px;
    margin-left: 90px;
  }

  /* =============== FLIGHT FORM ========= */
}



@media only screen and (max-width: 600px) {

  /* NAVBAR CSS */
  .callingtxt {
    display: none;
  }

  .callingmbltxt {
    color: #1F1A17;
    text-align: center;
    font-size: 13px;
    margin: 0%;
  }

  .callingmbltxt h5 {
    padding-top: 6px;
    margin: 0%;
    font-size: 20px;
    font-weight: 650;
  }

  .callingmbltxt h5 a {
    color: #ECC52C;
    list-style-type: none;
    text-decoration: none;
  }


  /* MAIN IMAGES  */
  .d-home {
    height: 80vh;
  }

  /* Searching function  */
  .d-form {
    width: 100% !important;
    margin-left: 0%;
  }

  .fly_form {
    width: 100% !important;
    margin-left: 0%;
  }


  .brightfare__m_logo img {
    width: 75% !important;
    height: 67px !important;
    margin: 0% !important;
    /* height: 20% !important; */
    margin-top: -10px !important;
    margin-left: -10px !important;
  }

  .d-form .SubmitBTn {
    width: 65%;
    padding: 10px 0px;
    font-size: 16px;
    transition: 0.7s;
    /* display: flex; */
  }

  .fly_form .SubmitBTn {
    width: 80%;
    padding: 10px 0px;
    font-size: 16px;
    transition: 0.7s;
    /* display: flex; */
  }

  .FLightModes {
    margin-left: 5px;
  }

  /* .d-fly .FLightModes {
    margin-top: 30%;
  } */

  .FLightModes Label {
    padding: 18px;
    font-size: 15px !important;
  }

  .fly_form label {
    font-size: 10px;
  }


  .fly_form .form-control {
    height: 50px;
    width: 100%;
    padding: 0px 15px;
    font-size: 13px;
  }

  .dataResult {
    font-size: 10.8px;
    /* line-height: 5px; */
    width: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35s) 0px 5px 15px;
    overflow: hidden !important;
  }

  .dataResult .dataItem:hover {
    background-color: rgb(195, 195, 195);
    /* height: 50px; */
    transition: 0.2s;
    border-radius: 20px;
    color: black;
    cursor: pointer;
  }

  .AirlinesResult {
    font-size: 13px;
    line-height: 5px;
    width: 100%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35s) 0px 5px 15px;
    overflow: hidden;
  }

  .AirlinesResult .AirlinesItem:hover {
    background-color: rgb(195, 195, 195);
    /* height: 50px; */
    transition: 0.2s;
    border-radius: 20px;
    color: black;
    cursor: pointer;
  }

  .d-form {
    color: black;
    font-weight: 600;
    box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
    -webkit-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
    -moz-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
    border-radius: 10px;
    background-color: white;
    padding: 40px 5px;
    /*   width: 95% !important;
    margin-left: 10px;*/
  }

  .fly_form {
    color: black;
    font-weight: 600;
    box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
    -webkit-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
    -moz-box-shadow: 2px -1px 21px 5px rgba(0, 0, 0, 0.64);
    border-radius: 10px;
    background-color: white;
    padding: 40px 25px;
    /*   width: 95% !important;
    margin-left: 10px;*/
  }

  .fly_form .form-control {
    /* height: 50px; */
    /* width: 100%; */
    padding: 0px 15px;
    font-size: 13px;
  }

  .Airlinesimg {
    margin-top: 132%;
    width: 100%;
    height: 100%;
  }

  .d-form label {
    font-size: 10px;
  }

  .d-form .form-control {
    /* height: 50px; */
    /* width: 100%; */
    padding: 0px 15px;
    font-size: 13px;
  }

  .images {
    overflow: hidden !important;
    width: 91%;
    height: 100%;
  }

  .nav_list ul li .nav-link {
    text-align: center;
    margin-left: 0%;
  }


  .Designation {
    margin-top: 100%;
    width: 100%;
    height: 100%;
  }

  .button-container button {
    padding: 8px 50px;
    font-size: 13px;
    margin-bottom: 0% !important;
  }


  /* confirmaton csss  */

  .message {
    text-align: center;
    padding: 40px;
  }

  .message h3 {
    color: #e0ae17;
  }

  .message h2 {
    color: black;
    font-size: 20px;
    font-weight: 600;
  }

  .message .ThankMsg h5 {
    color: rgb(44, 44, 44);
    font-size: 13px;
  }

  .message h1 {
    font-size: 20px;
  }


  /*================ TERMS & CONDITION CSS  START ================*/
  .Terms .content p {
    text-transform: capitalize;
    margin-top: 5px;
    line-height: 30px;
    font-size: 14px !important;
  }

  .Terms .content strong {
    font-size: 19px;
    /* color: #e0ae17; */
    color: #F0C632;
  }

  /*================ TERMS & CONDITION CSS END ================*/

  /* =====================  HAJJ & UMMRAH CSS START ============ */

  .hujjContent {
    font-size: 14px;
  }

  .hujjContent h3 {
    font-size: 15px;
    font-weight: bolder;
    width: 50;
    text-align: center;
    color: #e0ae17;
    line-height: 27px;
  }

  .hujjContent strong {
    color: #e0ae17;
  }

  .images .img1 {
    height: 38px;
    margin-left: 180px;
  }

  /* =========== ABOUT CSS ============ */
  .AboutImg {
    height: 30vh;
    padding-top: 2%;
  }

  .AboutContent {
    font-size: 14px;
  }

  .AboutContent h2 {
    font-weight: bold;
  }

  .AboutImg .About {
    font-size: 35px;
  }

  /* ===========ACCORDION CSS ================ */
  .Accordion_content .Summary_content {
    font-size: 13px !important;
  }

  /* =============== CONTACT CSS =============== */
  .Contactimg {
    height: 30vh;
  }

  /* ================== GOOGLE MAP ================= */
  .googlemap {
    width: 100%;
  }

  /* ============= FOOTER CSS ============== */
  .footer_container {
    margin: 0%;
    line-height: 25px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .footer_container li {
    font-size: 13px;
  }

  footer h4 {
    font-size: 18px;
    color: #e0ae17;
    margin-bottom: 0.6rem;
  }


  /*============= IMG WITH TEXT 1 CSS==============  */
  .company_vision {
    font-size: 13px;
  }

  .brightfair_vision .vision_web {
    display: none;
  }

  .Why_Need_Agent .Why_Need_Agent_web {
    display: none;
  }
}



/* ============ PRELOADER CSS START================= */
/* .preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
}

.preloader.hidden {
  opacity: 0;
  pointer-events: none;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(460deg);
  }
} */


/*  preloader */
#loading {
  background-color: #1F1A17;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  margin-top: 0px;
  top: 0px;
  transition: 4s ease-out !important;
  /* Add transition for opacity */
}

#loading.loaded {
  opacity: 0;
  /* Hide the preloader when loaded */
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#preloader_logo_center {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.object {
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 5px solid #e0ae17;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #FFF;
  border-right: 5px solid transparent;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

#object_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

#object_two {
  left: 65px;
  top: 65px;
  width: 70px;
  height: 70px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#object_three {
  left: 55px;
  top: 55px;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#object_four {
  left: 45px;
  top: 45px;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes animate {
  50% {
    -ms-transform: rotate(360deg) scale(0.8);
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}

@keyframes animate {
  50% {
    -ms-transform: rotate(360deg) scale(0.8);
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}





.scrollToTop {
  width: 45px;
  height: 45px;
  line-height: 43px;
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: #363636;
  display: none;
}

.scrollToTop.fixed-scroll {
  display: block;
}

.scrollToTop {
  position: fixed;
  right: 0px;
  bottom: 40px;
  opacity: .7;
  cursor: pointer;
  z-index: 99;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

.scrollToTop span {
  color: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  z-index: 1;
}

.scrollToTop span {
  font-size: 20px;
}

/* ============ PRELOADER CSS END ================= */


/* =============== SIDEBAR =================== */
.sidebarlogo img {
  width: 100%;
  /* height: 80px; */
}

.sidebarnav {
  color: white;
  line-height: 20px;
  text-decoration: none !important;
  transition: transform 0.3s ease !important;
}

.sidebarnav:hover {
  color: #e0ae17;
}

.sidebarContainer {
  height: 100%;
  /* Set the width */
  background-color: #1F1A17;
  /* Set the background color */
}